<template>
  <div class="dashboard">
    <helmet>
      <title>{{$t('title.dashboard')}}</title>
    </helmet>
    <!--    <breadcrumb>-->
    <!--      <router-link to="/dashboard">{{$t('common.dashboard')}}</router-link>-->
    <!--    </breadcrumb>-->
    <div class="dashboard__content">
      <TitleHeader>{{$t('dashboard.yourChannels')}}</TitleHeader>
      <el-table
        :data="channelsData"
        :default-sort="{prop: 'name', order: 'ascending'}"
        :empty-text="$t('dashboard.noChannels')"
        :row-class-name="tableRowClassName">
        <el-table-column
          prop="name"
          :label="$t('dashboard.labels.name')"
          sortable
          width="160"/>
        <el-table-column
          prop="paid_until"
          :label="$t('dashboard.labels.subscription')"
          sortable
          :formatter="paidUntilFormatter"/>
        <el-table-column
          :label="$t('dashboard.labels.actions')"
          width="120">
          <template slot-scope="{row: channel}">
            <router-link class="button" :to="`/channel/${channel.cid}`">{{$t('dashboard.actions.open')}}</router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import Helmet from '@/components/Helmet.vue'

  import TitleHeader from '@/components/TitleHeader.vue'
  import Breadcrumb from '@/components/Breadcrumb.vue'

  import subscriptionMethodsMixin from '@/mixins/subscriptionMethods'

  import store from '@/store'
  import { chain } from '@/functions/utils'
  import {
    ensureCheckAuth,
    ensureGetChannels,
    ensureGetUser,
  } from '@/functions/data'

  export default {
    name: 'Dashboard',
    mixins: [
      subscriptionMethodsMixin,
    ],
    components: {
      Helmet,

      TitleHeader,
      Breadcrumb,
    },
    methods: {
      paidUntilFormatter(channel) {
        return this.getSubscriptionText(channel)
      },
      tableRowClassName({row: channel}) {
        if (this.isSubscriptionEnded(channel)) {
          return 'subscription-ended'
        }
        return ''
      },
    },
    computed: {
      channels() {
        return this.$store.state.auth.userChannels
      },
      channelsData() {
        return this.channels.map((channel) => ({
          ...channel,
          ...channel.paid_until && {
            paid_until: new Date(channel.paid_until),
          }
        }))
      },
    },
    beforeRouteEnter(to, from, next) {
      return chain(
        {to, from, store},
        next,
        [
          ensureCheckAuth,
          ensureGetUser,
          ensureGetChannels,
        ]
      )
    },
  }
</script>

<style scoped lang="stylus">
  mobile = "(max-width 599px)"

  .dashboard
    @apply px-12 pt-3 pb-12

    @media mobile
      @apply px-6
</style>

<style lang="stylus">
  .dashboard
    .subscription-ended td
      background-color #fef0f0 !important

    .subscription-ended:hover td
      background-color #fde7e7 !important
</style>
