<template>
  <div class="title-header">
    <router-link class="button button_icon title-header__back-button" v-if="backTo" :to="backTo">
      <BackIcon/>
    </router-link>
    <h1 class="title-header__text"><slot/></h1>
  </div>
</template>

<script>
  import BackIcon from '@/assets/icons/keyboard_backspace-24px.svg'

  export default {
    name: 'TitleHeader',
    components: {
      BackIcon,
    },
    props: ['backTo'],
  }
</script>

<style scoped lang="stylus">
  mobile = "(max-width 599px)"

  .title-header
    @apply text-5xl
    @apply font-bold

    @media mobile
      @apply text-4xl

  .title-header__text
    display inline-block

  .title-header__back-button
    @apply mr-3
    position relative
    top 0.55rem

    @media mobile
      top .75rem
</style>